@import "../../../shared/scss/Common-Style.scss";

.modal-newcategory {
  .modal-dialog {
    .modal-body {
      .subhead {
        p {
          display: inline-block;
        }
      }

      .category-common {
        position: relative;
        padding-bottom: 20px;

        &:after {
          background: #b1b1b1;
          content: "";
          height: 1px;
          left: 0;
          position: absolute;
          bottom: 0;
          width: 96%;
        }

        &:last-child {
          &:after {
            content: none;
          }
        }
      }
    }
  }
}

.create-levels p.plusmenu-danger {
  text-align: right;
  margin-right: 1rem;
  cursor: pointer;
}