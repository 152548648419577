// Font Variables
$toyota-regular: 'Toyota-Regular';
$toyota-bold: 'Toyota-Bold';
$toyota-book: 'Toyota-Book';
$toyota-semibold: 'Toyota-Semibold';
$toyota-black: 'Toyota-Black';
$toyota-light: 'Toyota-light';

// Breakpoints
$breakpoint-mobile: 414px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;