.header {
  padding: 1em;
  box-shadow: .2px .2px 4px 0.5px #a5a4a4;
  position: relative;

  @media (min-width: 768px) {
    padding: 1em 5em;
  }

  .header-wrap {
    max-width: 1500px;
    margin: 0 auto;
  }

  .logo-wrap {
    img {
      max-width: 80px;

      @media (min-width: 768px) {
        max-width: 110px;
      }
    }

    .lexus-logo {
      img {
        position: absolute;
        top: 23px;
        margin-left: 20px;

        @media (min-width: 768px) {
          top: 20px;
          margin-left: 25px;
        }
      }
    }
  }
}