@font-face {
  font-family: "Toyota-Bold";
  src: local("Toyota-Bold"),
    url('./fonts/ToyotaType/ToyotaType-Bold.woff2') format('woff2'),
    url('./fonts/ToyotaType/ToyotaType-Bold.woff') format('woff'),
    url('./fonts/ToyotaType/ToyotaType-Bold.eot') format('eot'),
    url('./fonts/ToyotaType/ToyotaType-Bold.ttf') format("truetype"),
    url('./fonts/ToyotaType/ToyotaType-Bold.svg') format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: block
}

@font-face {
  font-family: "Toyota-Regular";
  src: local("Toyota-Regular"),
    url('./fonts/ToyotaType/ToyotaType-Regular.woff2') format('woff2'),
    url('./fonts/ToyotaType/ToyotaType-Regular.woff') format('woff'),
    url('./fonts/ToyotaType/ToyotaType-Regular.eot') format('eot'),
    url('./fonts/ToyotaType/ToyotaType-Regular.svg') format('svg'),
    url('./fonts/ToyotaType/ToyotaType-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block
}

@font-face {
  font-family: "Toyota-Book";
  src: local("Toyota-Book"),
    url('./fonts/ToyotaType/ToyotaType-Book.woff2') format('woff2'),
    url('./fonts/ToyotaType/ToyotaType-Book.woff') format('woff'),
    url('./fonts/ToyotaType/ToyotaType-Book.eot') format('eot'),
    url('./fonts/ToyotaType/ToyotaType-Book.svg') format('svg'),
    url('./fonts/ToyotaType/ToyotaType-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: block
}

@font-face {
  font-family: "Toyota-Semibold";
  src: local("Toyota-Semibold"),
    url('./fonts/ToyotaType/ToyotaType-Semibold.woff2') format('woff2'),
    url('./fonts/ToyotaType/ToyotaType-Semibold.woff') format('woff'),
    url('./fonts/ToyotaType/ToyotaType-Semibold.eot') format('eot'),
    url('./fonts/ToyotaType/ToyotaType-Semibold.svg') format('svg'),
    url('./fonts/ToyotaType/ToyotaType-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: block
}

@font-face {
  font-family: "Toyota-light";
  src: local("Toyota-light"),
    url('./fonts/ToyotaType/ToyotaType-Light.woff2') format('woff2'),
    url('./fonts/ToyotaType/ToyotaType-Light.woff') format('woff'),
    url('./fonts/ToyotaType/ToyotaType-Light.eot') format('eot'),
    url('./fonts/ToyotaType/ToyotaType-Light.eot') format('svg'),
    url('./fonts/ToyotaType/ToyotaType-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: block
}

// Font Variables
$toyota-regular: 'Toyota-Regular';
$toyota-bold: 'Toyota-Bold';
$toyota-book: 'Toyota-Book';
$toyota-semibold: 'Toyota-Semibold';
$toyota-light: 'Toyota-light';

body {
  margin: 0;
  font-family: $toyota-regular, Calibri, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.modal-open {
  pointer-events: none;
}

body.modal-open .Toastify {
  pointer-events: all;
}

code {
  font-family: $toyota-regular;
}

p,
button,
a {
  font-family: $toyota-regular;
}

strong {
  font-family: $toyota-bold;
}

button:focus {
  box-shadow: none !important;
  outline: none !important;
}

// Breakpoints
$breakpoint-mobile: 414px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;

// Manage Permission/Preferences Pop Up Design
.modalpopup {
  &.logout {
    z-index: 9999;

    .modal-dialog {
      @media (min-width: $breakpoint-tablet) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        margin: 0;
        max-width: 480px;
      }
    }

    .modal-content {
      @media (min-width: $breakpoint-tablet) {
        padding: 0.375em !important;
      }
    }

    .modal-body {
      @media (min-width: $breakpoint-tablet) {
        padding: 0.375em 0 0.375em 1.375em !important;
      }
    }
  }

  .modal-dialog {
    max-width: 720px;

    .modal-header,
    .modal-footer {
      border: none;
    }

    .modal-footer {
      padding: 0;
    }

    .modal-header {
      border-bottom: 1px solid #b1b1b1;

      button {
        &.btn-close {
          border: 2px solid #000;
          border-radius: 50%;
          opacity: 1;
          position: absolute;
          right: 25px;
          top: 20px;

          &:focus {
            outline: none;
            border: 2px solid #000;
            box-shadow: none;
          }
        }
      }

      p {
        margin-bottom: 0;
        font-family: $toyota-book;
      }
    }

    .modal-body {
      text-align: left;
      padding: 1em 1em 2em 1em;
      max-height: 650px;
      overflow-y: auto;

      @media (min-width: $breakpoint-tablet) {
        padding: 0.375em 0.375em 3.375em 2.375em;
        max-height: 720px;
      }

      @media (min-width: 991px) {
        max-height: 480px;
      }

      .row {
        margin: 20px 0;
        font-family: $toyota-book;

        p {
          margin-bottom: 0;
          font-family: $toyota-book;
          color: #1d1d1d;

          &.delete-txt {
            color: #FF0000;
            font-size: 14px;
            line-height: 20px;
            margin: 0 0 10px 0;
            max-width: 250px;

            @media (min-width: $breakpoint-tablet) {
              max-width: 540px;
            }
          }
        }

        .help-sec {
          position: absolute;
          top: 10px;
          right: 25px;
          cursor: pointer;

          .help-icon {
            margin-right: 3px;

            svg {
              height: 25px;
              width: 25px;
              fill: #707070;
            }
          }

          p {
            color: #1D1D1D;
            font-family: $toyota-book;
            font-size: 12px;

          }
        }

        input,
        select,
        textarea {
          border: 1px solid #b1b1b1;
          border-radius: 0;
          font-family: $toyota-book;

          &[readonly] {
            cursor: not-allowed;
          }

          &::placeholder {
            font-size: 14px;
            line-height: 20px;
            color: #1D1D1D;
          }

          &:focus,
          &:active,
          &:hover {
            outline: none;
            box-shadow: none;
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            box-shadow: transparent;
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }

          +label {

            &:focus,
            &:active,
            &:hover {
              outline: none;
              box-shadow: none;
            }
          }
        }

        textarea {
          width: 100%;
          height: 70px;
          border: 1px solid #919191;

          @media (min-width: $breakpoint-tablet) {
            height: 80px;
            width: 523px;
          }

          &:read-only {
            background: #e9ecef;
            cursor: not-allowed;
          }
        }

        &.date-wrap {
          input {
            @media (max-width: $breakpoint-tablet) {
              margin-bottom: 15px;
            }
          }
        }

        .warning-img {
          img {
            max-width: 75px;
          }

          @media (max-width: $breakpoint-tablet) {
            text-align: center;
            margin-bottom: 25px;
          }
        }

        .warning-message {
          padding-left: 30px;
        }

        &.selection {
          input {
            visibility: hidden;
            height: 0;
            width: 0;
            display: inline;
            padding: 0;

            &[readonly] {
              +label {
                cursor: not-allowed;
              }
            }

            +label {
              position: relative;
              cursor: pointer;
              font-family: $toyota-book;

              &::after {
                height: 15px;
                width: 15px;
                border: 1px solid #707070;
                content: "";
                position: absolute;
                right: -25px;
                top: 7px;
              }
            }

            &:checked {
              +label {
                &::before {
                  content: "";
                  position: absolute;
                  height: 5px;
                  width: 10px;
                  border: 0;
                  top: 11px;
                  right: -22px;
                  border-left: 2px solid #707070;
                  border-bottom: 2px solid #707070;
                  transform: rotate(-45deg);
                }
              }
            }
          }
        }

        &.category-sec {
          input {
            margin-top: 10px;
          }

          .add-icon {
            position: relative;

            .plusmenu-danger {
              border: none;
              background-color: transparent;
              position: absolute;
              top: -95px;
              right: 10px;

              @media (min-width: $breakpoint-tablet) {
                top: -45px;
                right: 30px;
              }
            }
          }
        }

        // Display none to be removed if Enable Email functionality to be added back
        &.enable-email {
          display: none;
        }

        .category-options {
          @media (max-width: $breakpoint-tablet) {
            margin-top: 15px;
          }

          .btn {
            border: 1px solid #D9DEE2;
            border-radius: 0;
            font-family: $toyota-book;

            @media (min-width: $breakpoint-tablet) {
              width: 200px;
            }

            &:focus,
            &.active &:not(:focus) {
              outline: none;
              box-shadow: none;
            }

            &.btn-dark,
            &.btn-light {

              &:focus,
              &.active {
                outline: none;
                box-shadow: none;
              }
            }
          }
        }

        .email-options {
          &[readonly] {
            pointer-events: none;
            cursor: not-allowed;
          }

          @media (max-width: $breakpoint-tablet) {
            margin-top: 15px;
          }

          .btn {
            border: 1px solid #D9DEE2;
            border-radius: 0;
            width: 75px;

            &:focus,
            &.active &:not(:focus) {
              outline: none;
              box-shadow: none;
            }

            &.btn-dark,
            &.btn-light {

              &:focus,
              &.active {
                outline: none;
                box-shadow: none;
              }
            }
          }
        }
      }

      .select-default-modes {
        background: #F5F5F5;
        padding: 10px 15px;
        margin: 10px;
        font-family: $toyota-book;

        @media (min-width: $breakpoint-tablet) {
          padding: 10px 25px;
        }

        &[readonly] {
          cursor: not-allowed;

          input {
            +label {
              pointer-events: none;
            }
          }

          span {
            pointer-events: none;
          }
        }

        .comm-mode {
          padding-bottom: 10px;

          .mode-comm-group {
            display: flex;
            position: relative;

            @media (max-width: $breakpoint-tablet) {
              margin-top: 25px;
            }
          }

          span {
            &.btn {
              border-radius: 50%;
              box-shadow: none;
              margin-top: 0 !important;
              margin-right: 20px;
              border: none;
              padding: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: $toyota-book;

              img {
                height: 20px;
                width: 20px;
              }

              p {
                position: absolute;
                top: 32px;
                margin-top: 10px;
                font-size: 13px;

                &.mode-labels {
                  text-transform: capitalize;
                  top: 30px;
                }
              }

              &.btn-dark {
                background: #000;
                border: 1px solid #000;
              }

              &.btn-light {
                background: #fff;
                border: 1px solid #BEBEBE;
              }
            }

          }
        }

        .default-mode {
          .row {
            padding-top: 1rem;
          }

          input {
            visibility: hidden;
            height: 0;

            +label {
              position: relative;
              cursor: pointer;
              margin-right: 40px;
              margin-left: 5px;
              font-family: $toyota-book;

              &::after {
                height: 20px;
                width: 20px;
                border: 1px solid #707070;
                content: "";
                position: absolute;
                right: -10px;
                top: -15px;
              }
            }

            &:checked {
              +label {
                &::before {
                  content: "";
                  position: absolute;
                  height: 6px;
                  width: 14px;
                  border: 0;
                  top: -9px;
                  right: -7px;
                  border-left: 2px solid #707070;
                  border-bottom: 2px solid #707070;
                  transform: rotate(-45deg);
                }
              }
            }
          }
        }
      }

      .cst-note {
        p {
          font-size: 12px;
          line-height: 16px;
        }
      }

      .multi-options {
        margin: 30px auto 10px;

        .btn-group {
          display: flex;
          flex-direction: column;
          font-family: $toyota-book;

          @media (min-width: $breakpoint-tablet) {
            flex-direction: row;
          }
        }

        label {
          margin: 5px;

          p {
            font-size: 14px;
            line-height: 19px;
          }

          &.btn-light {
            background: #f2f2f2 0% 0% no-repeat padding-box;
            border: 1px solid #e5e5e5;
            box-shadow: none;
            border-radius: 0;
          }

          &.btn-dark {
            background: #58595b 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            box-shadow: none;
            border-radius: 0;
            position: relative;

            &::before {
              content: "";
              background: url("../src/assets/Group 305.svg");
              height: 15px;
              width: 15px;
              left: 10%;
              top: 8px;
              position: absolute;
              background-size: 100% 100%;

              @media (min-width: $breakpoint-tablet) {
                left: 5%;
              }
            }
          }
        }
      }

      .image-upload {
        input[type="file"] {
          &::-webkit-file-upload-button {
            display: none;
          }
        }

        input[readonly] {
          pointer-events: none;
          cursor: not-allowed;
        }

        @media (max-width: $breakpoint-tablet) {
          >div {
            display: inline-block;
            width: 50%;
          }
        }

        label {
          padding: 10px 20px;
          background: #DEDEDE 0% 0% no-repeat padding-box;
          cursor: pointer;
          font-size: 14px;
          line-height: 20px;
          font-family: $toyota-book;

          &[readonly] {
            pointer-events: none;
            cursor: not-allowed;
          }

          @media (min-width: $breakpoint-tablet) {
            padding: 10px 30px;
          }

          svg {
            margin-right: 5px;
          }
        }
      }
    }

    .button-options {
      display: flex;
      flex-direction: column;
      font-family: $toyota-book;

      @media (min-width: $breakpoint-tablet) {
        justify-content: flex-end;
        flex-direction: row;
      }

      &.logout {
        @media (min-width: $breakpoint-tablet) {
          justify-content: flex-start;
        }

        .btn {
          height: 45px;
        }
      }

      .btn {
        min-width: 100%;
        height: 55px;
        font-size: 16px;
        line-height: 16px;
        font-family: $toyota-bold;
        padding: 0 15px;
        margin: 10px 0;

        @media (min-width: $breakpoint-tablet) {
          min-width: 110px;
        }

        &:focus {
          box-shadow: none;
        }

        &.btn-primary {
          background: #fff;
          color: #000;
          border: 1px solid #707070;
          border-radius: 1px;
          margin-right: 20px;
        }

        &.btn-secondary {
          color: #fff;
          background: #000000 0% 0% no-repeat padding-box;
          border-radius: 1px;
          margin-right: 20px;
        }
      }
    }
  }
}

//Toastify Design

.Toastify {
  .Toastify__toast {
    border-radius: 0;
  }

  .Toastify__toast-body {
    >div {
      font-family: $toyota-regular;
      font-size: 14px;
      color: #000;
    }
  }
}