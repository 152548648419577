.modal-delete {
  .modal-dialog {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    position: absolute;

    .button-options {
      .btn {
        height: 40px;
      }
    }
  }
}