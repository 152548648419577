@import "../../../shared/scss/Common-Style.scss";

.recent-activity,
.recent-updates {
  overflow-x: auto;

  @media (max-width: $breakpoint-tablet) {
    margin-top: 30px;
  }

  p {
    margin-left: 15px;
    font-weight: bold;

    &.justify-center {
      text-align: center;
      font-weight: 400;
    }
  }

  table {
    width: 100%;
    font-family: $toyota-regular;

    th,
    td {
      text-align: left;
      padding: 10px 5px;

      @media (min-width: $breakpoint-tablet) {
        padding: 10px;
        width: 130px;
      }

      &.edit,
      &.view {
        width: 100px;
        text-align: center;
      }

      &.review {
        width: 200px;
      }
    }

    th {
      background: #D4D2D2 0% 0% no-repeat padding-box;
      font-size: 15px;
      line-height: 16px;
      white-space: nowrap;

      @media (min-width: $breakpoint-tablet) {
        font-size: 15px;
        line-height: 22px;
      }
    }

    td {
      font-size: 10px;
      line-height: 12px;
      min-height: 70px;
      height: 70px;

      @media (min-width: $breakpoint-tablet) {
        font-size: 13px;
        line-height: 20px;
      }

      &.edit,
      &.view,
      &.review {
        cursor: pointer;
        color: #2a79fd;
        text-decoration: underline;

        &:hover {
          color: #e10a1d;
        }
      }
    }

    tr {
      &:nth-child(even) {
        background: #E3E3E3 0% 0% no-repeat padding-box;
      }
    }
  }

  .pagenation {
    text-align: right;
    margin-right: 1em;

    .noof-records {
      display: inline-block;
      margin: 10px 10px;
      color: #8f8f8f;
    }

    .pagenation-numbs {
      display: inline-block;
      margin: 10px 10px;

      button {
        display: inline;
        padding: 7px 15px;
        border: 1px #dee2e6 solid;
        background-color: #fff;

        &:focus {
          background-color: #000;
          color: #fff;
        }

        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}


.recent-updates {
  margin-top: 40px;
}

.recent-activity-list {
  text-align: right;
  padding: 0 1.5rem 0 0;
  font-size: 14px;

  p {
    a {
      color: #2a79fd;

      &:hover {
        color: #e10a1d;
      }
    }
  }
}

.recent-activity-list-one {
  text-align: right;
  padding: 0 1.5rem 0 0;
  margin-bottom: 1rem;
  font-size: 14px;
}